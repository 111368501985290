
import Vue from "vue";
import TextDivider from "@/components/common/TextDivider.vue"

export default Vue.extend({
  name: "Support",
  components: {
    TextDivider
  },
  data() {
    return {
      equipments: [],
      report: false,
      file: undefined,
      tickets: [
        {
          name: "Matt Harris",
          content:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
        },
        {
          name: "Matt Harris",
          content:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
        },
        {
          name: "Matt Harris",
          content:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
        },
        {
          name: "Matt Harris",
          content:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
        },
      ],
    };
  },

  methods: {},
});
